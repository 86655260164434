import Cmp from './Homepage.js'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query currentHomepageQuery($prismicId: ID!) {
    prismicHomepage(prismicId: {eq: $prismicId}) {
      data {
        hero_text
        hero_image {
          fixed(height: 910, width: 1440) {
            src
          }
        }
        video_header
        video_id
        navigation {
          navigation_header
        }
        um_verkefnid_title {
          text
        }
        text_column_left {
          html
        }
        text_column_right {
          html
        }
        link_text {
          html
        }
        stadsetning_title {
          text
        }
        stadsetning_text {
          html
        }
        geopark_title {
          text
        }
        geopark_text {
          html
        }
        link_text {
          html
        }
        geopark_image {
          fixed(height: 400, width: 400) {
            src
          }
        }
        mynda_gallery {
          gallery_image {
            fixed(height: 910, width: 1440) {
              src
            }
          }
        }
        gallery_main_image{
          fixed(height: 620, width: 1440) {
            src
          }
        }
        gallery {
          gallery_image {
            fixed(height: 620, width: 1440) {
              src
            }
          }
        }
        vefmyndavel_title {
          text
        }
        hafdu_samband_title {
          text
        }
        footer_title {
          text
        }
        heimilisfang
        postnumer
        land
        netfang
      }
    }
  }`

export default Cmp
