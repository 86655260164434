import React from 'react'

/* eslint max-len: ["error", { "code": 5000 }] */
const SvgComponent = props => (
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" opacity="1">
    <title>EE6ED38A-AE6E-4B7A-A507-CD0535F9AF8D</title>
    <desc>Created with sketchtool.</desc>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Aðaltorg-Forsíða-Dark" transform="translate(-152.000000, -531.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="f144" transform="translate(152.000000, 531.000000)">
          <path d="M21.796875,13.632812 L11.484375,7.363281 C11.015625,7.089844 10.546875,7.089844 10.078125,7.363281 C9.609375,7.636719 9.375,8.046875 9.375,8.59375 L9.375,20.78125 C9.375,21.328125 9.609375,21.738281 10.078125,22.011719 C10.546875,22.285156 11.015625,22.285156 11.484375,22.011719 L21.796875,16.09375 C22.265625,15.820312 22.5,15.410156 22.5,14.863281 C22.5,14.316406 22.265625,13.90625 21.796875,13.632812 Z M29.53125,14.6875 C29.53125,12.070312 28.876953,9.648438 27.568359,7.421875 C26.259766,5.195312 24.492188,3.427734 22.265625,2.119141 C20.039062,0.810547 17.617188,0.15625 15,0.15625 C12.382812,0.15625 9.960938,0.810547 7.734375,2.119141 C5.507812,3.427734 3.740234,5.195312 2.431641,7.421875 C1.123047,9.648438 0.46875,12.070312 0.46875,14.6875 C0.46875,17.304688 1.123047,19.726562 2.431641,21.953125 C3.740234,24.179688 5.507812,25.947266 7.734375,27.255859 C9.960938,28.564453 12.382812,29.21875 15,29.21875 C17.617188,29.21875 20.039062,28.564453 22.265625,27.255859 C24.492188,25.947266 26.259766,24.179688 27.568359,21.953125 C28.876953,19.726562 29.53125,17.304688 29.53125,14.6875 Z M3.28125,14.6875 C3.28125,12.578125 3.808594,10.625 4.863281,8.828125 C5.917969,7.03125 7.34375,5.605469 9.140625,4.550781 C10.9375,3.496094 12.890625,2.96875 15,2.96875 C17.109375,2.96875 19.0625,3.496094 20.859375,4.550781 C22.65625,5.605469 24.082031,7.03125 25.136719,8.828125 C26.191406,10.625 26.71875,12.578125 26.71875,14.6875 C26.71875,16.796875 26.191406,18.75 25.136719,20.546875 C24.082031,22.34375 22.65625,23.769531 20.859375,24.824219 C19.0625,25.878906 17.109375,26.40625 15,26.40625 C12.890625,26.40625 10.9375,25.878906 9.140625,24.824219 C7.34375,23.769531 5.917969,22.34375 4.863281,20.546875 C3.808594,18.75 3.28125,16.796875 3.28125,14.6875 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
