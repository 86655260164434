import React, { Component } from 'react'
import styles from './SectionLocation.module.scss'
import classNames from 'classnames'
import Map from '../Map/Map'

class SectionLocation extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currZoom: 9,
      activeZoom: 0,
      locations: [
        {
          title: 'Reykjanes',
          zoom: 9
        },
        {
          title: 'Flugvöllur',
          zoom: 12
        },
        {
          title: 'Nálægt',
          zoom: 15
        }
      ]
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (zoom, index) {
    // location will be strength of zoom
    this.setState({ currZoom: zoom, activeZoom: index })
  }

  render () {
    const { title, text } = this.props
    const locations = this.state.locations

    return (
      <section id='1' className={classNames(styles.container)}>
        <div className='container'>
          <div className='row pl-xl-2 pl-lg-0'>
            <div className={classNames('col-lg-6 col-md-12', styles.textBox)}>
              <div className='d-flex justify-content-start'>
                <h2 className={classNames('hdln--2 mt-5 mb-4', styles.headerText)}>{title}</h2>
              </div>

              <div
                className='text-style-white justify-content-center pt-2 mb-5'
                dangerouslySetInnerHTML={{ __html: text }}
              />

              <div className='pt-3 mb-5 d-flex'>
                {
                  locations.map((location, i) => {
                    return (
                      <div className={classNames('pr-lg-4 pr-3')} key={i}>
                        <button
                          className={classNames(styles.button, { [styles.activeButton]: i === this.state.activeZoom })}
                          onClick={() => this.handleClick(location.zoom, i)}
                          dangerouslySetInnerHTML={{ __html: location.title }}
                        />
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='col-md-5 pr-0 pl-0'>
              <Map location={this.state.currZoom} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionLocation
