import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import styles from './SectionWebCam.module.scss'
import classNames from 'classnames'
import axios from 'axios'

class SectionWebCam extends Component {
  constructor (props) {
    super(props)

    this.state = {
      northEastView: '',
      southWestView: ''
    }
  }

  componentDidMount () {
    axios.get('https://wrapapi.com/use/arnorhs/adaltorg/webcams/0.0.5?wrapAPIKey=MQUt2VASwVBqSUNfZIWB2tXYR0TIpFG5')
      .then(result => {
        this.setState({
          northEastView: result.data.data['north-east-view'],
          southWestView: result.data.data['south-west-view']
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  render () {
    const { title } = this.props
    const { northEastView, southWestView } = this.state

    return (
      <section id='webcam' className={classNames(styles.container, 'pb-5')}>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <div className='mt-5 d-flex justify-content-center'>
                <h2 className={classNames('hdln--2 pt-3', styles.headerText)}>{title}</h2>
              </div>
            </div>
          </div>
        </div>
        <Tabs>
          <TabList className='d-flex justify-content-center mb-3'>
            <Tab>
              <div className='text-center'>
                <p className={styles.linkText}>Norðaustur vél</p>
              </div>
            </Tab>
            <Tab>
              <div className='text-center'>
                <p className={styles.linkText}>Suðvestur vél</p>
              </div>
            </Tab>
          </TabList>
          <TabPanel className='row d-flex justify-content-center'>
            <img src={northEastView} alt='Latest upload' width='752' height='425' />
          </TabPanel>
          <TabPanel className='row d-flex justify-content-center'>
            <img src={southWestView} alt='Latest upload' width='752' height='425' />
          </TabPanel>
        </Tabs>
        <div className='row'>
          <div className='col mt-3 mb-3 d-flex justify-content-center'>
            <a className={classNames(styles.olderVideos)} href='http://adalgata60.online/'>Sjá eldri mánuði</a>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionWebCam
