import React from 'react'
import '../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss'
import Carousel, { Modal, ModalGateway } from 'react-images'
import styles from './SectionGeopark.module.scss'
import classNames from 'classnames'

class SectionGeopark extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      modalIsOpen: false
    }

    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal () {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }

  render () {
    const { backgroundImage, title, text, linkText, gallery } = this.props
    const { modalIsOpen } = this.state
    const images = []

    gallery.map((image, i) => {
      const addImage = { src: image.gallery_image.fixed.src }
      return images.push(addImage)
    })

    return (
      <section id='2' className={styles.container}>
        <div className='container'>
          <div className='row pt-5 pb-3'>
            <div className='col-lg-6'>
              <h2
                className={classNames('hdln--2 mt-2 mb-4', styles.headerText)}
              >
                {title}
              </h2>

              <div className='row justify-content-start'>
                <div
                  className='col text-style'
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </div>

              <div className='pb-4'>
                <div
                  className={classNames(
                    'justify-content-start',
                    styles.viewDetails
                  )}
                >
                  <button
                    className='button--nostyle view-details-link'
                    onClick={this.toggleModal}
                    dangerouslySetInnerHTML={{ __html: linkText }}
                  />
                </div>
              </div>

              <ModalGateway>
                {modalIsOpen ? (
                  <Modal onClose={this.toggleModal}>
                    <Carousel views={images} />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
            <div className='row col-lg-6 d-flex justify-content-end'>
              <img src={backgroundImage} alt='Geopark' className='mb-auto' />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionGeopark
