import React from 'react'
import styles from './Map.module.scss'
// import classNames from 'classnames'

const Map = props => {
  const zoom = props.location
  const link = "https://maps.google.com/maps?q=63%C2%B059'57.9%22N%2022%C2%B035'02.1%22W&t=&z=" + zoom + '&ie=UTF8&iwloc=&output=embed'

  return (
    <iframe title='map' className={styles.map}
      src={link}
      frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0' />
  )
}

export default Map
