import React from 'react'
import styles from './SectionFooter.module.scss'
import classNames from 'classnames'

const SectionFooter = ({ title, heimilisfang, postnumer, land, netfang }) => {
  return (
    <section className={classNames(styles.container, 'pb-6')}>
      <div className='container'>
        <div className='row'>
          <div className='col-6'>
            <div className='pt-5 d-flex justify-content-start'>
              <h2 className={classNames('hdln--2', styles.headerText)}>{title}</h2>
            </div>
            <p className={styles.textStyle}>{heimilisfang}</p>
            <p className={styles.textStyle}>{postnumer}</p>
            <p className={styles.textStyle}>{land}</p>
            <p className={styles.textStyle}>{netfang}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionFooter
