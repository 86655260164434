import React, { Component } from 'react'
import styles from './Transition.module.scss'
import classNames from 'classnames'

class Transition extends Component {
  constructor (props) {
    super(props)
    this.state = { transitionIn: false }
  }

  componentDidMount () {
    this.setState({ transitionIn: true })
  }

  render () {
    const { children, isPanelTransition } = this.props
    const containerClass = classNames(
      styles.container,
      { [styles.containerPanel]: isPanelTransition },
      { [styles.transition]: this.state.transitionIn }
    )
    return (
      <div className={containerClass}>
        {children}
      </div>
    )
  }
}

export default Transition
