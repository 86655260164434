import React from 'react'
import Main from '../../layouts/Main'
import {
  Transition,
  Hero,
  SectionFrontInfo,
  SectionLocation,
  SectionGeopark,
  SectionGallery,
  SectionWebCam,
  SectionContactUs,
  SectionFooter
} from '../../components/'
import styles from './Homepage.module.scss'

const Homepage = ({ data }) => {
  const pageData = data.prismicHomepage.data

  return (
    <Main className={styles.container}>
      <Transition>
        <Hero
          backgroundImage={pageData.hero_image.fixed.src}
          text={pageData.hero_text}
          videoId={pageData.video_id}
          videoHeader={pageData.video_header}
          navigation={pageData.navigation}
        />
        <SectionFrontInfo
          title={pageData.um_verkefnid_title.text}
          leftColumnText={pageData.text_column_left.html}
          rightColumnText={pageData.text_column_right.html}
        />
        <SectionLocation
          title={pageData.stadsetning_title.text}
          text={pageData.stadsetning_text.html}
        />
        <SectionGeopark
          backgroundImage={pageData.geopark_image.fixed.src}
          title={pageData.geopark_title.text}
          text={pageData.geopark_text.html}
          linkText={pageData.link_text.html}
          gallery={pageData.mynda_gallery}
        />
        <SectionGallery
          mainImage={pageData.gallery_main_image.fixed.src}
          gallery={pageData.gallery}
        />
        {/* Hide this section temporarily */}
        {/* <SectionWebCam title={pageData.vefmyndavel_title.text} /> */}
        <SectionContactUs title={pageData.hafdu_samband_title.text} />
        <SectionFooter
          title={pageData.footer_title.text}
          heimilisfang={pageData.heimilisfang}
          postnumer={pageData.postnumer}
          land={pageData.land}
          netfang={pageData.netfang}
        />
      </Transition>
    </Main>
  )
}

export default Homepage
