import React from 'react'
import styles from './SectionGallery.module.scss'
import '../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss'
import Carousel, { Modal, ModalGateway } from 'react-images'
import classNames from 'classnames'

class SectionGallery extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      modalIsOpen: false
    }

    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal () {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }

  render () {
    const { mainImage, gallery } = this.props
    const { modalIsOpen } = this.state
    const images = []

    gallery.map((image, i) => {
      const addImage = { src: image.gallery_image.fixed.src }
      return (
        images.push(addImage)
      )
    })

    return (
      <section id='gallery'>
        <div
          className={styles.container}
          style={{ backgroundImage: `url(${mainImage})` }}>
          <ModalGateway>
            {modalIsOpen ? (
              <Modal onClose={this.toggleModal}>
                <Carousel views={images} />
              </Modal>
            ) : null}
          </ModalGateway>
          <button
            className={classNames('button', styles.galleryButton)}
            onClick={this.toggleModal}>
            SKOÐA MYNDASAFN
          </button>
        </div>
      </section>
    )
  }
}

export default SectionGallery
