import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import styles from './SectionContactUs.module.scss'
import classNames from 'classnames'
import axios from 'axios'

const defaultErrors = {
  name: null,
  email: null,
  phone: null,
  message: null,
}

const emailApiUrl = process.env.NODE_ENV === 'production'
  ? '/.netlify/functions/hafa-samband'
  : 'http://localhost:8888/.netlify/functions/hafa-samband'

class SectionContactUs extends Component {
  constructor (props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',
      messageSent: false,
      error: {
        errors: defaultErrors
      }
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit () {
    const { name, email, phone, message } = this.state
    return axios.post(emailApiUrl, { name, email, phone, message })
      .then(result => {
        if (result.status === 200) {
          this.setState({
            messageSent: true,
            error: {
              errors: defaultErrors
            },
            name: '',
            email: '',
            phone: '',
            message: ''
          })
        }
      })
      .catch(e => {
        if (e.response.data.errorMessage && e.response.data.errorMessage.includes("timed out")) {
          this.setState({
            messageSent: true,
            error: {
              errors: defaultErrors
            },
            name: '',
            email: '',
            phone: '',
            message: ''
          })
          return
        }
        this.setState({
          error: e.response && e.response.data
            ? { errorMessage: 'Vinsamlegast fylltu út í alla reiti!', errors: e.response.data.errors || defaultErrors }
            : { errorMessage: 'Villa kom upp við að senda skilaboð!', errors: defaultErrors }
        })
      })
  }

  render () {
    const { title } = this.props
    const { name, email, phone, message, error, messageSent } = this.state
    return (
      <section id='3' className={styles.container}>
        <div className='container pb-6'>
          <div className='row'>
            <div className='col-12'>
              <div className='col d-flex justify-content-center'>
                <h2 className={classNames('hdln--2', styles.headerText)}>{title}</h2>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-lg-4 mb-3'>
                  <Form.Group controlId='formBasicEmail'>
                    <Form.Control
                      className={error && error.errors.name ? styles.inputFormError : styles.inputForm}
                      name='name'
                      placeholder='Fullt Nafn'
                      value={name}
                      onChange={this.handleChange} />
                  </Form.Group>
                </div>
                <div className='col-sm-12 col-lg-4 mb-3'>
                  <Form.Group controlId='formBasicEmail'>
                    <Form.Control
                      className={error && error.errors.email ? styles.inputFormError : styles.inputForm}
                      name='email'
                      placeholder='Netfang'
                      value={email}
                      onChange={this.handleChange} />
                  </Form.Group>
                </div>
                <div className='col-sm-12 col-lg-4 mb-3'>
                  <Form.Group controlId='formBasicEmail'>
                    <Form.Control
                      className={error && error.errors.phone ? styles.inputFormError : styles.inputForm}
                      name='phone'
                      placeholder='Símanumer'
                      value={phone}
                      onChange={this.handleChange} />
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='container col-lg-12 mt-3 mt-lg-0'>
                  <Form.Group controlId='formBasicEmail'>
                    <Form.Control
                      name='message'
                      as='textarea'
                      rows='3'
                      placeholder='Skilaboð'
                      value={message}
                      className={error && error.errors.message ? styles.textareaErrorStyle : styles.textareaStyle} onChange={this.handleChange} />
                  </Form.Group>
                </div>
              </div>
              {error && (
                <div className={classNames('mt-3 mb-3', styles.inputError)}>{error.errorMessage}</div>
              )}
              {messageSent && (
                <div className={classNames('mt-3 mb-3', styles.messageSent)}>Takk fyrir að hafa samband!</div>
              )}
              <div className='row'>
                <div className='container col-lg-12 mt-2 d-flex justify-content-start'>
                  <Button className='button button--send' onClick={this.handleSubmit}>
                    SENDA
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionContactUs
