import React, { Component } from 'react'
import styles from './Hero.module.scss'
import classNames from 'classnames'
import Logo from '../../assets/images/svg/Logo.js'
import PlayButton from '../../assets/images/svg/PlayButton'
import ModalVideo from 'react-modal-video'

class Hero extends Component {
  constructor (props) {
    super(props)

    this.state = {
      parallax: 0,
      isMounted: false,
      isOpen: false
    }

    this.handleOnScroll = this.handleOnScroll.bind(this)
    this.openModal = this.openModal.bind(this)
    this.scrollToView = this.scrollToView.bind(this)
  }

  scrollToView (view, blockStart) {
    const element = document.getElementById(view)
    element.scrollIntoView({ block: blockStart ? 'start' : 'center', behavior: 'smooth' })
  }

  handleOnScroll () {
    const coords = `${-window.pageYOffset / 14}px`
    this.setState({ parallax: coords })
  }

  openModal () {
    this.setState({ isOpen: true })
  }

  componentDidMount () {
    setTimeout(function () {
      this.setState({ isMounted: true })
    }.bind(this), 200)
    window.addEventListener('scroll', this.handleOnScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleOnScroll)
  }

  render () {
    const { backgroundImage, text, videoId, videoHeader, navigation } = this.props
    const buttonBootstrapStyle = 'col-lg-3 d-flex justify-content-lg-center justify-content-left mb-3'
    const scrollButtonStyle = classNames(
      'row align-items-center button',
      styles.playButton,
      styles.scrollButtonStyle
    )

    return (
      <section className={styles.container}>
        <div className={classNames(styles.heroBg)} style={{ backgroundImage: `url(${backgroundImage})` }} />
        <div className='container pt-1 pt-lg-5'>
          <div className='row'>
            <div className='col mt-0 mt-lg-6'>
              <div className={classNames(styles.logoContainer, 'pt-6')}>
                <Logo />
              </div>

              <div className='pt-3 pb-6'>
                <p className={styles.umAdaltorg}>{text}</p>
                <ModalVideo
                  channel='youtube'
                  isOpen={this.state.isOpen}
                  videoId={videoId}
                  onClose={() => this.setState({ isOpen: false })}
                />

                <div className='pt-3'>
                  <button
                    className={classNames('pl-0 mb-3 button', styles.playButton)}
                    onClick={() => this.openModal()}
                  >
                    <PlayButton />
                    <span className={classNames('ml-2', styles.textStyle)}>{videoHeader}</span>
                  </button>
                </div>
              </div>

              <div className='container'>
                <div className='row mt-lg-5 pt-lg-4 d-flex justify-content-center'>
                  {navigation.map((header, i) => {
                    return (
                      <div className={buttonBootstrapStyle} key={i}>
                        <button className={scrollButtonStyle} onClick={() => this.scrollToView(i)}>
                          <div className={styles.scrollNumberContainer}>
                            <p className={styles.scrollNumber}>0{i + 1}</p>
                          </div>
                          {header.navigation_header}
                        </button>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Hero
