import React from 'react'
import styles from './SectionFrontInfo.module.scss'
import classNames from 'classnames'

const SectionFrontInfo = ({ title, leftColumnText, rightColumnText }) => {
  return (
    <section id='0' className={styles.container}>
      <div className='container'>
        <div className='row pt-5 pb-5 d-flex justify-content-center'>
          <h2 className={classNames('hdln--2 mt-2 mb-4', styles.headerText)}>{title}</h2>
          <div className='col-lg-6 col-md-12'>
            <div className='text-style' dangerouslySetInnerHTML={{ __html: leftColumnText }} />
          </div>
          <div className='col-lg-6 col-md-12'>
            <div className='text-style' dangerouslySetInnerHTML={{ __html: rightColumnText }} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionFrontInfo
