import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Burger.module.scss'
import classNames from 'classnames'

class Burger extends Component {
  render () {
    const { active, clickEvent, isGreen } = this.props
    const containerClass = classNames(styles.container, { [styles.active]: active })
    const lineClass = classNames(styles.line, { [styles.green]: isGreen })

    return (
      <div className={styles.buttonWrapper}>
        <button
          className={containerClass}
          ref={el => { this.container = el }}
          onClick={clickEvent}
          data-cy='burger-icon'
        >
          <div
            className={lineClass}
          />
          <div
            className={lineClass}
          />
          <div
            className={lineClass}
          />
        </button>
      </div>
    )
  }
}

Burger.propTypes = {
  /** Toggles component active state */
  active: PropTypes.bool,
  /** onClick event handler */
  clickEvent: PropTypes.func
}
Burger.defaultProps = {
  active: false
}

export default Burger
